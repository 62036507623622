@tailwind base;
@tailwind components;
@tailwind utilities;

.fade-enter {
    opacity: 0;
}
.fade-enter.fade-enter-active {
    opacity: 1;

    transition: opacity 600ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit.fade-exit-active {
    opacity: 0;

    transition: opacity 600ms;
}

.leaflet-container {
    background-color: white !important;
}

.cluster-marker {
    color: #fff;
    background: #1978c8;
    border-radius: 50%;
    padding: 5px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leaflet-div-icon {
    background: none !important;
    border: none !important;
}
